// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".detail_content_20nWN{padding:.58667rem .85333rem 2.4rem}.detail_content_20nWN .detail_banner_2E2Eo{width:8.29333rem;height:8.29333rem;border-radius:.64rem}.detail_content_20nWN .detail_title_3cV7q{margin:.4rem 0;text-align:center;font-size:.64rem;line-height:.88rem}.detail_content_20nWN .detail_infoWrap_8kzgD{display:flex;flex-wrap:wrap;margin-bottom:.66667rem}.detail_content_20nWN .detail_infoWrap_8kzgD .detail_info_G7aMY{width:50%;margin-bottom:.26667rem;line-height:.53333rem}.detail_content_20nWN .detail_infoWrap_8kzgD .detail_info_G7aMY:nth-child(2n){text-align:right}.detail_content_20nWN .detail_tagWrap_3Qj6F{background:#212121;box-shadow:0 .53333rem .8rem 0 rgba(0,0,0,.4);border-radius:.29333rem}.detail_content_20nWN .detail_tagWrap_3Qj6F .detail_tagTitle_3Ozpi{border-radius:.29333rem;padding:0 .42667rem;height:1.28rem;line-height:1.28rem;background:#212121}.detail_content_20nWN .detail_tagWrap_3Qj6F .detail_tagContent_31Dha{background:rgba(0,0,0,.2);padding:.64rem .48rem 0;display:flex;flex-wrap:wrap}.detail_content_20nWN .detail_tagWrap_3Qj6F .detail_tagContent_31Dha .detail_tag_232kY{margin:0 .50667rem .53333rem 0;width:1.41333rem;height:.64rem;line-height:.58667rem;text-align:center;font-size:.32rem;box-sizing:border-box;border-radius:.05333rem;border:.02667rem solid;-o-border-image:radial-gradient(circle,#5ffcff,#eeadef) 1 1;border-image:radial-gradient(circle,#5ffcff,#eeadef) 1 1}.detail_content_20nWN .detail_tagWrap_3Qj6F .detail_tagContent_31Dha .detail_tag_232kY:nth-child(4n){margin-right:0}", ""]);
// Exports
exports.locals = {
	"content": "detail_content_20nWN",
	"banner": "detail_banner_2E2Eo",
	"title": "detail_title_3cV7q",
	"infoWrap": "detail_infoWrap_8kzgD",
	"info": "detail_info_G7aMY",
	"tagWrap": "detail_tagWrap_3Qj6F",
	"tagTitle": "detail_tagTitle_3Ozpi",
	"tagContent": "detail_tagContent_31Dha",
	"tag": "detail_tag_232kY"
};
module.exports = exports;
