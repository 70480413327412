<template>
  <div>
    <Back />
    <div :class="$style.content">
      <img :class="$style.banner" :src="detail.image_url" />
      <h2 :class="$style.title">{{ detail.name }}</h2>
      <div :class="$style.infoWrap">
        <span :class="$style.info">类型：{{ detail.type }}</span>
        <span :class="$style.info">风格：{{ detail.style }}</span>
        <span :class="$style.info">回位：{{ detail.clip }}</span>
        <span :class="$style.info">构图：{{ detail.structure }}</span>
      </div>
      <div :class="$style.tagWrap">
        <div :class="$style.tagTitle">相关标签</div>
        <div :class="$style.tagContent">
          <span
            :class="$style.tag"
            v-for="item in detail.tag_list"
            :key="item"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <Pay
      v-if="isShowBuy && Number(detail.price) !== 0"
      :stock="isStock"
      :price="detail.price"
      @handlePay="handlePay"
    />
    <ActionSheet v-if="isShowActions" :info="detail" path="spin" />
  </div>
</template>

<script>
import Back from "@/components/Back";
import Pay from "@/components/Pay";
import ActionSheet from "@/components/ActionSheet";
import { fetchDdcDetail } from "@/api";

export default {
  components: {
    Back,
    Pay,
    ActionSheet,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
    };
  },
  computed: {
    // is_paid=1, is_display_buy_button = 0, 展示分享/赠送
    // is_paid=0, is_display_buy_button=0 什么都不展示
    // is_paid=1, is_display_buy_button=1 展示分享/赠送
    // is_paid=0, is_display_buy_button=1 展示购买按钮
    isShowBuy() {
      const { is_paid, is_display_buy_button } = this.detail;
      return Number(is_paid) === 0 && Number(is_display_buy_button) === 1;
    },
    isShowActions() {
      const { is_paid } = this.detail;
      return Number(is_paid) === 1;
    },
    // 是否有库存
    isStock() {
      const { stock } = this.detail;
      return Number(stock) !== 0;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const data = { ddc_id: this.id };
      fetchDdcDetail(data).then((res) => {
        this.detail = res;
      });
    },
    handlePay() {
      this.$router.push({
        path: "/pay",
        query: { ddc_id: this.id, price: this.detail.price },
      });
    },
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 22px 32px 90px;
  .banner {
    /* width: 100%; */
    width: 311px;
    height: 311px;
    border-radius: 24px;
  }
  .title {
    margin: 15px 0;
    text-align: center;
    font-size: 24px;
    line-height: 33px;
  }
  .infoWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    .info {
      width: 50%;
      margin-bottom: 10px;
      line-height: 20px;
      &:nth-child(2n) {
        text-align: right;
      }
    }
  }
  .tagWrap {
    background: #212121;
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.4);
    border-radius: 11px;
    .tagTitle {
      border-radius: 11px;
      padding: 0 16px;
      height: 48px;
      line-height: 48px;
      background: #212121;
    }
    .tagContent {
      background: rgba(0, 0, 0, 0.2);
      padding: 24px 18px 0;
      display: flex;
      flex-wrap: wrap;
      .tag {
        &:nth-child(4n) {
          margin-right: 0;
        }
        margin: 0 19px 20px 0;
        width: 53px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        font-size: 12px;
        box-sizing: border-box;
        border-radius: 2px;
        border: 1px solid;
        border-image: radial-gradient(
            circle,
            rgba(95, 252, 255, 1),
            rgba(238, 173, 239, 1)
          )
          1 1;
      }
    }
  }
}
</style>
